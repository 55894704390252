import Link from "next/link";

type Props = {
  buttonText: string;
  demoButtonText: string;
  text: string;
};

export default function ContactCTABlock({
  buttonText,
  demoButtonText,
  text,
}: Props) {
  return (
    <section className="bg-secondary py-24 text-white">
      <div className="mx-auto flex max-w-7xl flex-col items-center gap-8 px-6 text-center md:flex-row md:text-left">
        <div className="text-r-3xl max-w-2xl font-bold">{text}</div>
        <div className="shrink-0 md:ml-auto ">
          <Link href="/contact">
            <a className="btn-secondary">{buttonText}</a>
          </Link>
        </div>
        <div className="shrink-0">
          <Link href="https://meetings.hubspot.com/info3139">
            <a target="_blank" className="btn-primary">
              {demoButtonText}
            </a>
          </Link>
        </div>
      </div>
    </section>
  );
}
