import { Fragment } from "react";
import Image from "next/image";
import { Entry } from "contentful";

import { Testimonial } from "repos/testimonials";

type Props = {
  title: string;
  testimonials: Entry<Testimonial>[];
};

export default function TestimonialsBlock({ title, testimonials }: Props) {
  return (
    <section className="bg-gray-100 py-24">
      <div className="mx-auto mb-16 max-w-7xl px-6">
        <h2 className="text-r-3xl text-center font-bold">{title}</h2>
      </div>
      <div className="mx-auto mt-24 flex max-w-7xl  justify-center px-6 ">
        <div className="flex max-w-3xl flex-col md:flex-row">
          {testimonials.map((testimonial, idx) => (
            <Fragment key={testimonial.sys.id}>
              <div className="flex-1">
                <div className="mb-8 select-none">
                  <Image
                    src={`https:${testimonial.fields.image.fields.file.url}?fm=webp&h=36`}
                    alt={testimonial.fields.name}
                    width={200}
                    height={36}
                    objectFit="contain"
                    objectPosition="left"
                  />
                </div>
                <p className="text-r-lg mb-8 leading-relaxed">
                  {testimonial.fields.content}
                </p>
                <div className="mb-1 font-bold">{testimonial.fields.name}</div>
                <div className="text-r-sm">{testimonial.fields.title}</div>
              </div>
              {idx !== testimonials.length - 1 && (
                <div className="my-12 h-[1px] bg-gray-300 md:my-0 md:mx-12 md:h-full md:w-[1px]" />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  );
}
