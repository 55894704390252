import React from "react";
import { Entry } from "contentful";
import Image from "next/image";

import { Why } from "repos/whys";

type Props = {
  subtitle: string;
  title: string;
  whys: Entry<Why>[];
};

export default function WhysBlock(props: Props) {
  const { subtitle, title, whys } = props;

  return (
    <section className="bg-gray-100 py-24">
      <div className="mx-auto mb-16 max-w-7xl px-6">
        <h2 className="text-r-4xl text-center font-bold">{title}</h2>
        <p className="text-r-lg m-auto mt-8  text-center">{subtitle}</p>
      </div>
      <div className="mx-auto flex max-w-7xl flex-col justify-between gap-20 px-6 md:flex-row md:gap-16">
        {whys.map((why) => (
          <div className="flex-1 text-center" key={why.sys.id}>
            <Image
              src={`https:${why.fields.image.fields.file.url}`}
              alt={why.fields.image.fields.title}
              width={120}
              height={120}
              objectFit="contain"
              objectPosition="left"
            />
            <p className="text-r-2xl m-auto my-6  text-center font-bold">
              {why.fields.title}
            </p>
            <p className="text-r-lg m-auto mt-6  text-center">
              {why.fields.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}
