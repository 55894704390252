import { GetStaticPropsContext, InferGetStaticPropsType } from "next";

import ContactCTABlock from "components/ContactCTABlock";
import CustomersBlock from "components/CustomersBlock";
import HeroBlock from "components/HeroBlock";
import HighlightsBlock from "components/HighlightsBlock";
import Layout from "components/layout";
import SEO from "components/seo";
import SolutionsBlock from "components/SolutionsBlock";
import TestimonialsBlock from "components/TestimonialsBlock";
import WhysBlock from "components/WhysBlock";

import { getHomePage } from "repos/homePage";

export default function HomePage({
  homePage,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const {
    contactText,
    ctaButtonText,
    customers,
    customersTitle,
    demoButtonText,
    heroButtonText,
    heroSubtitle,
    heroTitle,
    highlights,
    industries,
    industriesTitle,
    solutionsSubtitle,
    solutionsTitle,
    testimonials,
    testimonialsTitle,
    useCases,
    useCasesTitle,
    whys,
    whySubtitle,
    whyTitle,
  } = homePage.fields;

  return (
    <Layout stickyHeader>
      <SEO description={heroSubtitle} />
      <HeroBlock
        title={heroTitle}
        subtitle={heroSubtitle}
        buttonText={heroButtonText}
      />
      <CustomersBlock customers={customers} title={customersTitle} />
      <WhysBlock subtitle={whySubtitle} title={whyTitle} whys={whys} />
      <HighlightsBlock highlights={highlights} />
      <TestimonialsBlock
        title={testimonialsTitle}
        testimonials={testimonials}
      />
      <SolutionsBlock
        industries={industries}
        industriesTitle={industriesTitle}
        subtitle={solutionsSubtitle}
        title={solutionsTitle}
        useCases={useCases}
        useCasesTitle={useCasesTitle}
      />
      <ContactCTABlock
        buttonText={ctaButtonText}
        demoButtonText={demoButtonText}
        text={contactText}
      />
    </Layout>
  );
}

export async function getStaticProps({
  locale,
  preview,
}: GetStaticPropsContext) {
  const homePage = await getHomePage({ locale, preview });

  return {
    props: { homePage },
  };
}
