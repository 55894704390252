import { Entry } from "contentful";
import Image from "next/image";
import { Solution as SolutionType } from "repos/solutions";

type SolutionProps = {
  solution: Entry<SolutionType>;
};

export default function Solution(props: SolutionProps) {
  const { solution } = props;

  return (
    <div className="flex items-start gap-4">
      <Image
        src={`https:${solution.fields.image.fields.file.url}`}
        alt={solution.fields.title}
        width={50}
        height={50}
      />
      <div className="flex-1">
        <p className="text-r-xl mb-2 font-bold">{solution.fields.title}</p>
        <p className="text-r-lg">{solution.fields.description}</p>
      </div>
    </div>
  );
}
